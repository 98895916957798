* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
 }
 
 body {
   font-family: sans-serif;
 }
 
 header {
   box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
   color: #212529;
 }
 
 .nav-area {
   display: flex;
   align-items: center;
   max-width: 1200px;
   margin: 0 auto;
   padding: 10px 20px;
 }
 
 .logo {
   text-decoration: none;
   font-size: 25px;
   color: inherit;
   margin-right: 20px;
 }
 
 /* menu on desktop */
 .desktop-nav .menus {
   display: flex;
   align-items: center;
   flex-wrap: wrap;
   list-style: none;
 }
 
 .desktop-nav .menu-items {
   position: relative;
   font-size: 14px;
 }
 
 .desktop-nav .menu-items a {
   display: block;
   font-size: inherit;
   color: inherit;
   text-decoration: none;
 }
 
 .desktop-nav .menu-items button {
   display: flex;
   align-items: center;
   color: inherit;
   font-size: inherit;
   border: none;
   background-color: transparent;
   cursor: pointer;
   width: 100%;
 }
 
 .desktop-nav button span {
   margin-left: 3px;
 }
 
 .desktop-nav .menu-items > a,
 .desktop-nav .menu-items button {
   text-align: left;
   padding: 0.7rem 1rem;
 }
 
 .desktop-nav .menu-items a:hover,
 .desktop-nav .menu-items button:hover {
   background-color: #f2f2f2;
 }
 
 .desktop-nav .arrow::after {
   content: "";
   display: inline-block;
   margin-left: 0.28em;
   vertical-align: 0.09em;
   border-top: 0.42em solid;
   border-right: 0.32em solid transparent;
   border-left: 0.32em solid transparent;
 }
 
 .desktop-nav .dropdown {
   position: absolute;
   right: auto;
   left: 0;
   box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
     0 4px 6px -2px rgba(71, 63, 79, 0.16);
   font-size: 0.875rem;
   z-index: 9999;
   min-width: 10rem;
   padding: 0.5rem 0;
   list-style: none;
   background-color: #fff;
   border-radius: 0.5rem;
   display: none;
 }
 
 .desktop-nav .dropdown.show {
   display: block;
 }
 
 .desktop-nav .dropdown .dropdown-submenu {
   position: absolute;
   left: 100%;
   top: -7px;
 }
 
 .mobile-nav {
   display: none;
 }
 
 /* menu on mobile */
 @media screen and (max-width: 960px) {
   .nav-area {
     justify-content: space-between;
   }
 
   .desktop-nav {
     display: none;
     text-align: right;
   }
 
   .mobile-nav {
     display: block;
   }
   .mobile-nav .mobile-nav__menu-button {
     color: inherit;
     font-size: inherit;
     border: none;
     background-color: transparent;
     cursor: pointer;
     position: relative;
   }
   .mobile-nav .menus {
     list-style: none;
     position: absolute;
     top: 50px;
     right: 20px;
     box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
       0 4px 6px -2px rgba(71, 63, 79, 0.16);
     z-index: 9999;
     min-width: 12rem;
     padding: 0.5rem 0;
     background-color: #fff;
     border-radius: 0.5rem;
   }
 
   .mobile-nav .menu-items a {
     display: block;
     font-size: inherit;
     color: inherit;
     text-decoration: none;
   }
 
   .mobile-nav .menu-items button {
     display: flex;
     align-items: center;
     color: inherit;
     font-size: inherit;
     border: none;
     background-color: transparent;
     cursor: pointer;
     width: 100%;
   }
 
   .mobile-nav .menu-items > a,
   .mobile-nav .menu-items button {
     text-align: left;
     padding: 0.7rem 1rem;
   }
 
   .mobile-nav .menu-items a:hover,
   .mobile-nav .menu-items button:hover {
     background-color: #f2f2f2;
   }
 
   .mobile-nav .arrow::after {
     content: "";
     display: inline-block;
     margin-left: 1.2em;
     vertical-align: 0.09em;
     border-top: 0.42em solid;
     border-right: 0.32em solid transparent;
     border-left: 0.32em solid transparent;
   }
   .mobile-nav .arrow-close::after {
     content: "";
     display: inline-block;
     margin-left: 1.2em;
     vertical-align: 0.09em;
     border-bottom: 0.42em solid;
     border-right: 0.32em solid transparent;
     border-left: 0.32em solid transparent;
   }
 
   .mobile-nav .dropdown {
     margin-left: 1.2em;
     font-size: 0.9rem;
     padding: 0.5rem 0;
     list-style: none;
     display: none;
   }
 
   .mobile-nav .dropdown.show {
     display: block;
   }
 }
 
 /* page content */
 .content {
   max-width: 1200px;
   margin: 0 auto;
   padding: 3rem 20px;
 }
 
 .content h1 {
   font-size: 2rem;
 }
 
 #error-page {
   /* center content on the page */
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   height: 100vh;
   gap: 20px;
 }

 .headshot {
  border-radius: 50%;
  object-fit: cover;
  width: 500px;
  height: 500px;
  float: right;
 }

 .app-body-1 {
  margin-top: 50px;
  line-height: 2;
 }

 @media (max-width: 550px) {
  .app-body-1 {
    margin-top: 340px;
  }
  .headshot {
    float: left;
    margin-top: 20px;
    margin-bottom: 20px;
  }
 }

 .link-arrow {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-left: 4px;
 }

 .arrow-link-text {
  display: inline-block;
  font-weight: bold;
  font-size: 20px;
  color: black;
 }

 .arrow-link {
  display: inline-block;
 }

 .app-arrow-link-div {
  display: flex;
  justify-content: left;
  gap: 50px;
  margin-left: 30px;
 }

.app-skills-div {
  display: grid;

  grid-template-columns: 120px 120px 120px;
  grid-template-rows: 120px 120px;

  justify-content: left;
  gap: 10%;
  margin-left: 30px;
}

@media (max-width: 1000px) {
  .app-skills-div {
    gap: 5%;
  }
  .app-arrow-link-div {
    gap: 30px;
  }
  .headshot {
    width: 300px;
    height: 300px;
  }
}

.python-logo {
  height: 80px;
  grid-column: 1;
  grid-row: 1;
}

.java-logo {
  height: 80px;
  grid-column: 2;
  grid-row: 1;
}

.rust-logo {
  height: 80px;
  grid-column: 3;
  grid-row: 1;
}

.js-logo {
  height: 80px;
  grid-column: 1;
  grid-row: 2;
}

.css-logo {
  height: 80px;
  grid-column: 2;
  grid-row: 2;
}

.html-logo {
  height: 80px;
  grid-column: 3;
  grid-row: 2;
}

.project-showcase-container {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 150px 150px;
  gap: 10px 25px;
}

.project-0, .project-1, .project-2, .project-3 {
  align-self: center;
  border-radius: 0%;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  height: 140px;
}

.project-0 {
  grid-row: 1;
  grid-column: 1;
  background-color: #D1E9B8;
  /* background-image: linear-gradient(to right, #D1E9B8 , white); */
}

.project-1 {
  grid-row: 1;
  grid-column: 2;
  background: #FAE8BC;
  /* background-image: linear-gradient(to left, #FAE8BC , white); */
}

.project-2 {
  grid-row: 2;
  grid-column: 1;
  background-color: #FACBCB;
  /* background-image: linear-gradient(to right, #FACBCB , white); */
}

.project-3 {
  grid-row: 2;
  grid-column: 2;
  background-color: lightblue;
  /* background-image: linear-gradient(to left, lightblue , white); */
}

.project-image {
  height: 100px;
  margin: 3px;
}

.project-inner {
  display: flex;
}

.project-inner-text {
  color: rgb(0, 0, 0);
  margin: 3px;
  align-self: right;
  /* font-family: "Jetbrains mono", "Courier New", monospace; */
}

.project-link-wrapper {
  text-decoration: none;
}

@media (max-width: 1000px) {
  .project-desc {
    opacity: 0%;
  }
  .project-image {
    height: 50px;
  }
  .project-showcase-container {
    grid-template-columns: auto;
  }
  .about-content-box {
    display: inline;
  }
}

.carousel-slide {
  height: 100%;
  width: auto;
  object-fit: cover;
}

.carousel-container {
  max-width: 420px;
  max-height: 240px;
}

.about-content-box {
  display: flex;
  gap: 20px;
}

h1.about-text-header {
  color: green;
}

h2.about-text-header {
  color: darkgreen;
  line-height: 1.5;
}

.about-text-p {
  line-height: 2;
}

@media (max-width: 1000px) {
  .about-content-box {
    display: inline;
  }
  .about-text {
    margin-top: 250px;
  }
}